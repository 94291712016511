import React, { useState, useEffect } from "react";
import { navigate } from "gatsby-link";
import country from "country-list-js";
import countryCodes from "country-codes-list";
import { LocaleAsString } from "../../../../localeEngine";

import {
  Input,
  Select,
  GoogleAutocomplete,
} from "../../../../components/forms";
import { Typography, Button } from "../../../../components/utils";
import Link from "../../../../components/link";
import LoginModal from "../../../../components/session/loginModal";

import { getSession } from "../../../../utils/localStorage";
import { getMainConfig } from "../../../../utils/getConfig";

import * as styles from "./styles.module.scss";

interface GuestDetailsProps {
  form: {
    [key: string]: string;
  };
  setForm: any;
  isLogged: Boolean;
  logout: Function;
  setCurrentStep: any;
}

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  prefix: string;
  phone: string;
  addressLine1: string;
  city: string;
  postalCode: string;
  country: string;
  repeatEmail: string;
  password: string;
}

const GuestDetails = (props) => {
  let { form, setForm }: { form: FormData; setForm: (a: any) => void } = props;
  const { isLogged, logout, setCurrentStep } = props;
  const [autocompletedAddress, setAutocompletedAddress] = useState({});
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const onSubmit = (e) => e.preventDefault();

  // country.all === dictionary
  // Object.values(country.all) === array
  const countryNames = Object.values(country.all)
    .map((country) => {
      return { value: country.iso3, label: country.name };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const phone = countryCodes.customList(
    "countryCallingCode",
    "{countryNameEn}"
  );

  const countryCode = countryCodes.customList("countryNameEn", "{countryCode}");
  const checkoutAsGuest = getMainConfig({ field: "checkoutAsGuest" });

  let dialingCodes = Object.keys(phone).map((code) => {
    return {
      value: `+${code}`,
      label: `+${code} (${phone[code]})`,
      flag: countryCode[phone[code]],
    };
  });

  dialingCodes = dialingCodes.sort((a, b) => a.label.localeCompare(b.label));

  const session = getSession();

  if (typeof session === "undefined") {
    return <div></div>;
  }

  if (typeof form === "undefined") {
    form = {
      firstName: "",
      lastName: "",
      email: "",
      prefix: "",
      phone: "",
      addressLine1: "",
      city: "",
      country: "",
      postalCode: "",
      repeatEmail: "",
      password: "",
    };
  }

  if (form.prefix === "") {
    let defaultPrefix = "";
    defaultPrefix = getMainConfig({ field: "defaultPrefix" });

    form.prefix = defaultPrefix;
  }

  if (form.country === "") {
    let defaultCountry = "";
    defaultCountry = getMainConfig({ field: "defaultCountry" });

    form.country = defaultCountry;
  }

  if (checkoutAsGuest && !session.customer) {
    form.password = "checkoutAsGuest";
  }

  useEffect(() => {
    if (
      session &&
      session.customer &&
      session.customer.email &&
      session.customer.email !== ""
    ) {
      setForm(session.customer);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(autocompletedAddress).length === 0) return;

    const currentForm = { ...form, ...autocompletedAddress };

    setForm(currentForm);
  }, [autocompletedAddress]);

  const handleChange = (name, event) => {
    const currentForm = { ...form };
    let value = event.target.value;
    if (name === "prefix") {
      if (value.indexOf("+") === -1) {
        value = "+" + value;
      }
    }
    currentForm[name] = value;
    setForm(currentForm);
  };

  const autoCompleteAddress = (place) => {
    const currentForm = {};

    let streetNumber = "";
    let route = "";

    place.address_components.forEach((component) => {
      if (!component.types.indexOf("country")) {
        const countryCode = component.short_name;
        const getNameByIso = country.findByIso2(countryCode).name;
        currentForm["country"] = getNameByIso;
      }
      if (!component.types.indexOf("locality"))
        currentForm["city"] = component.long_name;
      if (!component.types.indexOf("postal_town"))
        currentForm["city"] = component.long_name;
      if (!component.types.indexOf("postal_code"))
        currentForm["postalCode"] = component.long_name;
      if (!component.types.indexOf("street_number"))
        streetNumber = component.long_name;
      if (!component.types.indexOf("route")) route = component.long_name;
    });

    currentForm["addressLine1"] = `${route}${
      streetNumber ? ` ${streetNumber}` : ""
    }`;

    setAutocompletedAddress(currentForm);
  };

  const getLoginZone = () => {
    if (checkoutAsGuest) {
      return null;
    }

    if (isLogged) {
      return (
        <div className={styles.switchForm}>
          <Typography variant={"h300-medium"} component={"span"}>
            <LocaleAsString id="checkout.guest_details.welcome" />
            {", "}
            {session.customer.firstName}!
          </Typography>
          <br />
          <Button onClick={() => logout()} className={styles.logout}>
            <Typography variant={"h300-medium"} component={"strong"}>
              <LocaleAsString id="checkout.guest_details.log_out" />
            </Typography>
          </Button>
        </div>
      );
    }

    return (
      <div className={styles.switchForm}>
        <Typography variant={"h300-medium"} component={"span"}>
          <LocaleAsString id="checkout.guest_details.returning_customer" />{" "}
        </Typography>
        <Button
          className={styles.login}
          onClick={() => setOpenLoginModal(true)}
        >
          <Typography variant={"h300-medium"} component={"strong"}>
            <LocaleAsString id="checkout.guest_details.log_in" />
          </Typography>
        </Button>
      </div>
    );
  };

  return (
    <div className={styles.guestDetails}>
      {getLoginZone()}
      <form className={styles.form} onSubmit={onSubmit}>
        <Typography
          variant="h500-medium"
          component={"span"}
          className={styles.fullInput}
        >
          <LocaleAsString id="checkout.guest_details.guest_info" />
        </Typography>
        <Input
          className={styles.halfInput}
          label={<LocaleAsString id="checkout.guest_details.form.first_name" />}
          icon="User"
          required
          name="firstName"
          type="text"
          onChange={(e) => handleChange("firstName", e)}
          value={form.firstName}
        />
        <Input
          className={styles.halfInput}
          label={<LocaleAsString id="checkout.guest_details.form.last_name" />}
          icon="User"
          required
          name="lastName"
          type="text"
          onChange={(e) => handleChange("lastName", e)}
          value={form.lastName}
        />
        <Select
          className={styles.halfInput}
          name="prefix"
          label={<LocaleAsString id="checkout.guest_details.form.prefix" />}
          icon="Phone"
          onChange={(e) => handleChange("prefix", e)}
          value={form.prefix}
          options={dialingCodes}
        />
        <Input
          className={styles.halfInput}
          label={
            <LocaleAsString id="checkout.guest_details.form.phone_number" />
          }
          icon="Phone"
          required
          type="tel"
          name="phone"
          validation={form.prefix}
          onChange={(e) => handleChange("phone", e)}
          value={form.phone}
        />
        <GoogleAutocomplete
          className={styles.fullInput}
          onPlaceSelected={(place) => {
            autoCompleteAddress(place);
          }}
          options={{
            types: ["address"],
          }}
          label={<LocaleAsString id="checkout.guest_details.form.address" />}
          icon="Pin"
          required
          name="addressLine1"
          onChange={(e) => handleChange("addressLine1", e)}
          value={form.addressLine1}
        />
        <Input
          className={styles.halfInput}
          label={
            <LocaleAsString id="checkout.guest_details.form.postal_code" />
          }
          icon="Mailbox"
          required
          name="postalCode"
          validation={form.country}
          onChange={(e) => handleChange("postalCode", e)}
          value={form.postalCode}
        />
        <Input
          className={styles.halfInput}
          label={<LocaleAsString id="checkout.guest_details.form.city" />}
          icon="Buildings"
          required
          name="city"
          onChange={(e) => handleChange("city", e)}
          value={form.city}
        />
        <Select
          className={styles.fullInput}
          icon="Flag"
          options={countryNames}
          label={<LocaleAsString id="checkout.guest_details.form.country" />}
          required
          name="country"
          onChange={(e) => handleChange("country", e)}
          value={form.country}
        />
        {!isLogged && (
          <>
            <Typography
              variant="h500-medium"
              component={"span"}
              className={styles.fullInput}
            >
              <LocaleAsString id="checkout.guest_details.account_info" />
            </Typography>
            <Input
              className={styles.fullInput}
              label={<LocaleAsString id="checkout.guest_details.form.email" />}
              icon="Email"
              type="email"
              required
              name="email"
              onChange={(e) => handleChange("email", e)}
              value={form.email}
            />
            <Input
              className={styles.fullInput}
              label={
                <LocaleAsString id="checkout.guest_details.form.repeat_email" />
              }
              icon="Email"
              type="email"
              required
              name="repeatEmail"
              repeatField={form.email !== form.repeatEmail}
              repeatError="repeatEmail"
              disablePaste={true}
              onChange={(e) => handleChange("repeatEmail", e)}
              value={form.repeatEmail}
            />
            {!checkoutAsGuest && (
              <Input
                className={styles.fullInput}
                label={
                  <LocaleAsString id="checkout.guest_details.form.password" />
                }
                icon="Lock"
                required
                name="password"
                type="password"
                onChange={(e) => handleChange("password", e)}
                value={form.password}
              />
            )}
          </>
        )}
      </form>
      {openLoginModal && (
        <LoginModal
          closeFn={() => setOpenLoginModal(false)}
          loginCallback={() => {
            setCurrentStep(2);
          }}
        />
      )}
    </div>
  );
};

export default GuestDetails;
